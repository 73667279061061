@import '~antd/dist/antd.less';

@font-face {
  font-family: Bree;
  src: url('assets/fonts/paulaner/Paulaner1634-Medium.woff2') format('woff2'),
       url('assets/fonts/paulaner/Paulaner1634-Medium.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: Bree;
  src: url('assets/fonts/paulaner/Paulaner1634-Regular.woff2') format('woff2'),
       url('assets/fonts/paulaner/Paulaner1634-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: Bree;
  src: url('assets/fonts/paulaner/Paulaner1634-SemiBold.woff2') format('woff2'),
       url('assets/fonts/paulaner/Paulaner1634-SemiBold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: Bree;
  src: url('assets/fonts/paulaner/Paulaner1634-Bold.woff2') format('woff2'),
       url('assets/fonts/paulaner/Paulaner1634-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: Bree;
  src: url('assets/fonts/paulaner/Paulaner1634-ExtraBold.woff2') format('woff2'),
       url('assets/fonts/paulaner/Paulaner1634-ExtraBold.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Black.ttf);
  font-weight: 800;
}

body {
  margin: 0;
  font-family: Roboto, 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --green: #001E3C;
  --black: #D6BC83;
  --white: #FFFFFF;
  --gray: #C51A1B;
  --darkGray: #001E3C;
  --offRed: #FEF2F4;
  --lightred: #FE9596;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: #A1A1A1;
  font-weight: 500;
  font-size: 12px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--darkGray);
  font-weight: 700;
}

.ant-tabs-ink-bar {
  height: 4px !important;
}

.ant-tabs-nav::before {
  border-bottom: 1px solid #A4A4A4 !important;
}

/* .ant-switch-checked {
  background-color: var(--green);
} */

.ant-layout-sider-zero-width-trigger {
  background-color: var(--darkGray);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.ant-form-item-control {
  width: 100%;
}

.editor-product .ant-select-selector {
  background-color: #FBFBFB !important;
  border-color: #D2D2D2 !important;
}

.editor-product .ant-upload.ant-upload-select {
  width: 100%;
}

.ant-checkbox input {
  border-color: #BFBFBF;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

.main-button {
  width: 300px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 14px;
}

@primary-color: #102E54;@border-radius-base: 4px;