.wrapper {
  height: 100%;
    display: flex;
    flex-direction: column;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
.back {
  display: flex;
  align-items: center;
  color: var(--green);
  font-family: Bree;
  font-weight: 700;
  font-size: 26px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.back:hover {
  color: var(--green);
}

.backButton {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  color: white;
  margin-right: 15px;
}

.picker {
  border-color: #C9C9C9;
  border-radius: 5px !important;
  margin-right: 15px;
}

.boletinTable {
  padding-top:1em;
}