.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #CFCFCF;
  padding-bottom: 15px;
}

.title {
  color: var(--darkGray);
  font-family: Bree;
  font-weight: 700;
  font-size: 20px;
}

.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.input, .input :global(.ant-select-selector) {
  background-color: #FBFBFB !important;
  border-color: #D2D2D2 !important;
  border-radius: 4px !important;
}

.itemColumn {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.itemSubmit {
  margin-bottom: 0;
  text-align: right;
  margin-top: 20px;
}

.submit {
  width: 250px;
  font-weight: 700;
  font-size: 13px;
}

.uploadImage {
  border: 1px solid #D2D2D2;
  background-color: #FBFBFB;
  color: #383838;
  font-weight: 500;
  width: 100%;
  height: 39px;
  border-radius: 4px;
}

.center {
  text-align: center;
  display: block;
}

.form :global(.ant-upload-select) {
  width: 100%;
}