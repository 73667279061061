.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit, .delete {
  cursor: pointer;
  margin-top: 3px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;
}

.input {
  background-color: #FBFBFB;
  border-color: #D2D2D2;
  border-radius: 4px;
  width: 300px;
  margin-right: 20px;
}
