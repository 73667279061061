.header {
    padding-bottom: 10px;
    border-bottom: 1px solid #CFCFCF;
    margin-top: -7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header span {
    color: var(--darkGray);
    font-family: Bree;
    font-weight: 700;
    font-size: 18px;
  }
  
  .empty {
    width: 80%;
    margin: 40px auto 0 auto;
  }
  
  .label {
    font-weight: 500;
    color: var(--darkGray);
    font-size: 12px;
  }
  
  .input, .input .ant-select-selector {
    background-color: #FBFBFB;
    border-color: #D2D2D2;
    border-radius: 4px;
  }
  
  .form {
    margin-top: 20px;
  }
  
  .form :global(.ant-upload-select) {
    width: 100%;
  }
  
  .uploadImage {
    border: 1px solid #D2D2D2;
    background-color: #FBFBFB;
    color: #383838;
    font-weight: 500;
    width: 100%;
    height: 35px;
    border-radius: 4px;
  }
  
  .submitWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .itemSubmit {
    margin-bottom: 0;
  }
  
  .submit {
    width: 200px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    margin: 0 auto;
  }
  
  .center {
    text-align: center;
    display: block;
  }
  
  .imgWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .preview {
    height: 120px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #E8E8E8;
    margin-bottom: 30px;
  }
  
  .titleCategory {
    color: var(--green);
    display: block;
    margin-bottom: 15px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .swtichWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  .swtichWrap .label {
    margin-right: 10px;
  }
  
  .noMargin {
    margin-bottom: 0;
  }