.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.header h1 {
  color: var(--green);
  font-size: 28px;
  font-family: Bree;
  font-weight: 700;
  margin-bottom: 0;
}

.header button {
  background-color: var(--green);
  border-color: var(--green);
  color: white;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
  padding: 6.4px 40px;
}

.input {
  background-color: #FBFBFB;
  border-color: #D2D2D2;
  border-radius: 4px;
  width: 300px;
  margin-right: 20px;
}

.header button:hover,  .header button:focus{
  background-color: var(--green);
  border-color: var(--green);
  color: white;
}

.empty {
  background-color: white;
  padding: 25px 40px;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  border-radius: 6px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty span {
  color: var(--darkGray);
  font-size: 16px;
  display: block;
  margin-top: 15px;
}

.rowWrap {
  padding-bottom: 40px;
}

.scrollableCol {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 8px;
}

.fixedCol {
  position: sticky;
  top: 0;
  height: auto;
  overflow: hidden;
}
