.table {
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  color: var(--darkGray);
}

.row {
  padding: 10px;
}

.header {
  font-weight: 500;
  font-size: 12px;
  padding: 12px;
}

.cell {
  text-align: center;
}

.item {
  background-color: #B5FFCC;
  cursor: pointer;
}

.left {
  text-align: left;
}

.preparationIcon {
  width: 20px;
  height: 20px;
}

.onTheWay {
  background-color: #FBF9A9 !important;
}

.onPreparation {
  background-color: #B5E1FF !important;
}

.canceled {
  background-color: var(--offRed) !important;
}

.center {
  text-align: center;
}