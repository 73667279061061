.editor {
  background-color: white;
  padding: 25px 40px;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  border-radius: 6px;
}

.header h1 {
  font-family: Bree;
  font-weight: 700;
  color: var(--darkGray);
  font-size: 24px;
}

.section {
  border-bottom: 1px solid #D2D2D2;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.activeProduct > div {
  float: right;
}

.labelColumn {
  display: block;
}

.itemColumn {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.blue {
  color: #15234C;
}

.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.input, .input .ant-select-selector {
  background-color: #FBFBFB;
  border-color: #D2D2D2;
  border-radius: 4px;
  width: 100%;
}

.uploadImage {
  border: 1px solid #D2D2D2;
  background-color: #FBFBFB;
  color: #383838;
  font-weight: 500;
  width: 100%;
  height: 35px;
  border-radius: 4px;
}

.photoEmpty {
  background-color: #E8E8E8;
  height: 120px;
}

.photoWrap {
  margin-bottom: 20px;
}

.photo {
  border: 1px solid #E8E8E8;
  height: 120px;
  object-fit: cover;
}

.firstSubsection {
  border-right: 1px dashed #ACACAC;
  padding-right: 30px;
}
.secondSubsection {
  padding-left: 30px;
}

.labelSubsection {
  margin-bottom: 30px;
  display: block;
  font-size: 13px;
}

.nutricionalValues {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nutricionalValues .input {
  width: 200px;
}
.nutricionalValues .label {
  width: 70px;
  text-align: left;
}

.labelTopping {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.labelTopping span {
  display: block;
  margin-right: 5px;
}

.inputTopping {
  margin-top: 10px;
}

.itemTopping {
  margin-bottom: 0;
}

.trash {
  cursor: pointer;
}

.addToppingWrapper {
  height: 90px;
  display: flex;
  align-items: center;
}

.addTopping {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.freeToppings {
  width: 200px;
}

.addTopping span {
  color: var(--darkGray);
  font-weight: 500;
  display: block;
  margin-left: 5px;
}

.center {
  text-align: center;
  display: block;
  margin-top: 5px;
}

.submitWrap {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.itemSubmit {
  margin-bottom: 0;
}

.submit {
  width: 250px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
}

.delete {
  margin-right: 15px;
}

.delivery {
  margin-right: 25px;
}