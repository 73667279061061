:global(.ant-select-tree-checkbox):global(.ant-select-tree-checkbox-disabled) {
    display: none;
  }
  
  :global(li.ant-select-tree-treenode-disabled) > span:not(:global(.ant-select-tree-switcher)),
  :global(li.ant-select-tree-treenode-disabled) > :global(.ant-select-tree-node-content-wrapper),
  :global(li.ant-select-tree-treenode-disabled) > :global(.ant-select-tree-node-content-wrapper) span {
    cursor: default;
  }
  
  :global(li.ant-select-tree-treenode-disabled):global(.ant-select-tree-treenode-switcher-close) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    margin-top: 0;
    padding-top: 4px;
  }