.category {
  margin-bottom: 30px;
}

.categoryHeader {
  display: flex;
  align-items: center;
  color: var(--darkGray);
  font-weight: 700;
  border-bottom: 2px solid #CCCCCC;
  padding-bottom: 10px;
}

.categoryHeader .categoryName {
  margin-left: 10px;
  text-transform: uppercase;
}

.categoryIcon {
  width: 20px;
}

.products {
  color: var(--darkGray);
  padding-top: 15px;
  padding-left: 35px;
  font-size: 16px;
}

.product {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active {
  color: var(--green);
  font-weight: 700;
}

.bullet {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--green);
  position: absolute;
  left: -15px;
}