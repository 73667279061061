.card {
  background-color: white;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  width: 231px;
  border-radius: 6px;
  height: 179px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 40px;
}

.label {
  font-weight: 500;
  display: block;
  margin-top: 10px;
  color: var(--darkGray);
}

.image {
  width: 60px;
}
