.order {
  color: var(--darkGray);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.title {
  font-family: Bree;
  font-weight: 700;
  color: var(--darkGray);
  font-size: 20px;
}

.green {
  color: var(--green);
}

.clientInfo {
  background-color: var(--darkGray);
  color: white;
  padding: 10px 20px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 25px;
}

.clientDetail {
  display: block;
}

.labelClient {
  display: inline-block;
  margin-right: 5px;
}

.clientAddress {
  text-align: center;
}

.clientPhone {
  text-align: right;
}

.products {
  margin-bottom: 15px;
}

.product {
  border-bottom: 1px dashed #bfbfbf;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.bullet {
  display: inline-block !important;
  margin-right: 25px;
}

.productName {
  font-family: Bree;
  font-size: 18px;
}

.details {
  padding-left: 25px;
  margin-top: 5px;
}

.details span {
  display: block;
}

.totalProduct {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
}

.deliveryWrap {
  background-color: #f3f3f3;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.price {
  font-weight: 500;
}

.label {
  font-weight: 500;
  font-size: 13px;
}

.tipWrap {
  padding: 15px;
  margin: 5px 0;
  border-bottom: 1px dashed #bfbfbf;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.totalPrice {
  font-size: 15px;
  font-weight: 700;
}

.ticket {
  font-weight: 800;
}

.labelSection {
  font-family: Bree;
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
}

.methodWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-top: 1px solid #c2c2c2;
  color: #444444;
}

.methodWrap:last-child {
  border-bottom: 1px solid #c2c2c2;
}

.cashback {
  font-weight: 700;
}

.method {
  display: flex;
  align-items: center;
}

.methodIcon {
  margin-right: 15px;
}

.paymentWrap {
  margin-bottom: 30px;
}

.driverName {
  font-weight: 700;
}

.selectDriver {
  width: 100%;
  border-color: #d2d2d2;
  background-color: #fbfbfb;
}

.selectDriver .ant-select-selector {
  border-radius: 6px !important;
}

.driverAssign,
.accept,
.saveNotes {
  font-weight: 700;
  font-size: 12px;
}

.driverAssign {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.saveNotesWrap {
  display: flex;
  justify-content: flex-end;
}

.saveNotes {
  width: 150px;
  margin-top: 10px;
}

.historyWrap {
  margin-top: 30px;
}

.prevOrder {
  border-top: 1px solid #c2c2c2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
}

.prevOrder:last-child {
  border-bottom: 1px solid #c2c2c2;
}

.seeMore {
  display: block;
  text-align: right;
  margin-top: 10px;
  text-decoration: underline;
  color: var(--darkGray);
}

.seeMore:hover {
  color: var(--darkGray);
}

.stepsWrap {
  margin-top: 30px;
  border: 1px dashed #a8a8a8;
  background-color: #f3f3f3;
  border-radius: 4px;
  padding: 30px;
}

.step {
  display: flex;
  border-top: 1px solid #d5d5d5;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
}

.step:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.submitWrap {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.accept {
  width: 200px;
}

.reject {
  margin-right: 20px;
}

.annotations {
  margin: 25px 0;
  color: var(--darkGray);
}

.labelNotes {
  font-weight: 700;
}

.mainInfoWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.call {
  padding: 5px 15px;
  background-color: var(--green);
  color: white;
  font-weight: 700;
  margin-left: 20px;
  border-radius: 4px;
  display: block;
}

.step span:first-child {
  margin-right: 15px;
}

.step span:last-child {
  text-align: right;
}
.discount {
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  /* font-size: 15px; */
}

.reasonWrap {
  margin-top: 30px;
}

.productPromo {
  margin-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .mainInfoWrap {
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: -25px;
    display: flex;
  }

  .paymentWrap {
    margin-top: 20px;
  }

  .clientAddress {
    text-align: left;
  }

  .clientPhone {
    text-align: left;
    color: #444444;
  }

  .labelClient {
    display: block;
    color: var(--darkGray);
    font-size: 12px;
  }

  .clientDetail {
    margin-bottom: 15px;
  }

  .clientInfo {
    background-color: transparent;
    color: #444444;
    font-weight: 500;
    font-size: 13px;
    padding-left: 0;
  }

  .submitWrap {
    width: 100%;
  }

  .accept {
    width: 100%;
  }
}
